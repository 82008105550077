import React from 'react'
import { domain } from "../BackendUrl";
import ArrowNext from './svgComponents/ArrowNext';
import ArrowPrevious from './svgComponents/ArrowPrevious';
import Slider from 'react-slick';

const ImageViewModal = ({
	imageAlt,
	images,
	postId,
	imageToOpen
}) => {

	const sliderSettings = {
		waitForAnimate: false,
		fade: true,
        customPaging: function(i) {
			console.log(images)
			const imgUrl = `${domain}${images[i]}`
			return (
			  <a>
				<img 
				src={imgUrl} 
				alt={`Thumbnail ${i}`}
				style={{
					width:"60px",
					height:"50px",
					objectFit: 'cover',
				}}/>
			  </a>
			);
		  },
		dots: true,
		dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
		initialSlide: imageToOpen
    };

	function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div className={`${className} d-flex flex-column slider-arrow`} onClick={onClick} > 
            <ArrowNext  />
          </div>
          
        );
      }
      
    function SamplePrevArrow(props) {
        const {className, style, onClick } = props;
        return (
            <div className={`${className} d-flex flex-column slider-arrow`} onClick={onClick} >
              <ArrowPrevious />
            </div>
        );
    }

	return (
		<>
			<div className='image-view-slider-container'>
				<Slider 
					{...sliderSettings}>
					{
						images.map((item, index) => {
							const imgUrl = `${domain}${item}`
							return (
								<div 
									key={`${imgUrl}img${index}`}
									className='image-container'
									>
									<img 
										id={`cardId${postId}`} 
										className="img-fluid" 
										style={{padding:'5px'}} 
										src={imgUrl} 
										alt={imageAlt} />
								</div>  
							)
						})
					}
				</Slider>
			</div>
		</>
	)
}

export default ImageViewModal;